// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Rubik:wght@300&display=swap');

.select-currency .react-autosuggest__suggestions-container {
  background-color: #181924;
}

.text-small {
  font-size: 10px;
}

.ghostit {
  opacity: 0.7;
}

h2.swal2-title {
  font-size: 1.5rem;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.orderForm {
  .form-control {
    font-size: 16px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: capitalize;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.select-error {
  & > div {
    border-color: #e55353;
  }
  & > div:hover {
    border-color: #e55353;
  }
}

.currency-input-error {
  & > input {
    border-color: #e55353;
  }
}

.react-select {
  & > div {
    min-height: 2.6rem;
  }
}
.selector-wrapper {
  & > div {
    flex: 1;
  }
  .input-group-append {
    flex: 0;
  }
}

.order-summary {
  text-align: left;
  font-size: 1rem;
}
.async-select {
  flex: 1;
  width: 100%;
  flex-grow: 1;
}

.smart-table tr td {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.smart-table tr {
  cursor: pointer;
}

.date-input-group {
  .form-control {
    line-height: 1.4rem;
  }
}

.currency-input {
  input {
    display: block;
    width: 100%;
    height: calc(1.65em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #5c6873;
    background-color: #fff;
    border-color: #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.currency-display {
  font-weight: 600;
}

.dropdown {
  &.nav-item {
    a {
      color: rgba(0, 0, 21, 0.5);
    }
  }
}

.form-control {
  height: auto;
}

.filterForm .form-control {
}

.strikethrough {
  text-decoration: line-through;

  &.ghost {
    color: rgb(189, 189, 189);
    .emojiFlag,
    .badge {
      opacity: 0.6;
    }
  }

  &.ghost ~ td {
    color: rgb(189, 189, 189);

    .emojiFlag,
    .badge {
      opacity: 0.6;
    }
  }

  & ~ td {
    text-decoration: line-through;
  }
}
